@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .button {
    @apply bg-[635fc7] py-2 px-4 rounded-full text-white text-lg font-semibold hover:opacity-80 duration-200;
  }
}

.dropdown {
  background-color: #00000080;
}

.filter-white {
  filter: invert(100%) sepia(96%) saturate(16%) hue-rotate(350deg)
    brightness(104%) contrast(100%);
}

.select-status {
  background-image: url("./assets/icon-chevron-down.svg");
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
}

.main-container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
}

.auth-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
